import React from 'react';
import MapComponent from './MapComponent';
import { Layout, MapContactContent, MapContacts, MapContactSubject, MapContainer, Title } from './styled';
export function CompanyMap() {
    return (React.createElement(Layout, { id: "5" },
        React.createElement(Title, null,
            "\uADF8\uB9B0\uB9AC\uBCF8\uACFC ",
            React.createElement("span", null, "\uD568\uAED8"),
            "\uD560",
            React.createElement("br", null),
            "\uC5EC\uB7EC\uBD84\uC744 \uAE30\uB2E4\uB9AC\uACE0 \uC788\uC2B5\uB2C8\uB2E4."),
        React.createElement(MapContainer, null,
            React.createElement(MapComponent, null)),
        React.createElement(MapContacts, null,
            React.createElement(MapContactSubject, null, "tel."),
            React.createElement(MapContactContent, { href: "tel:1660-2801" }, "1660-2801"),
            React.createElement(MapContactSubject, null, "(\uC6B4\uC601\uC2DC\uAC04 : \uD3C9\uC77C 10:00-16:00 / \uC810\uC2EC\uC2DC\uAC04 : \uD3C9\uC77C 12:00-13:00)"),
            React.createElement(MapContactContent, null),
            React.createElement(MapContactSubject, null, "mail."),
            React.createElement(MapContactContent, { href: "mailto:greenribbon@green-ribbon.co.kr" }, "greenribbon@green-ribbon.co.kr"))));
}
