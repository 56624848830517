import React, { useRef, useState } from 'react';
import { PolicyPath } from '~/constants/agreePolicy';
import { useGetViewPort } from '~/hooks/useGetViewPort';
import GreenSpacer from '../GreenSpacer';
import { EntrepreneurPageModal } from '../Modal/EntrepreneurModal';
import PdfViewer from '../PdfViewer';
import { Division, FooterDialog, FooterDialogCloseButton, GreenRibbonName, InfoDescCeoAndCorporateRegistrationNumber, InfoDescContainer, InfoTapContainer, InfoTapItem, LifeCatchFooterArea, LifeCatchFooterContainer, LifeCatchInfoDescTitleContainer, } from './styled';
const LifeCatchFooter = () => {
    const [, setIShowLifeCatchInfoDetail] = useState(false);
    const dialogRef = useRef(null);
    const [isMobile] = useGetViewPort();
    const [showLifeCatchAgreement, setShowLifeCatchAgreement] = useState(true);
    const [showPersonalInformationAgreement, setShowPersonalInformationAgreement] = useState(false);
    const [showInformationAboutEntrepreneurAgreement, setShowInformationAboutEntrepreneurAgreement] = useState(false);
    const [showElectronicFinanceAgreement, setShowElectronicFinanceAgreement] = useState(false);
    const showLifeCatchInfoDetail = () => setIShowLifeCatchInfoDetail(true);
    const allLifeCatchAgreementClose = () => {
        setShowLifeCatchAgreement(false);
        setShowPersonalInformationAgreement(false);
        setShowInformationAboutEntrepreneurAgreement(false);
        setShowElectronicFinanceAgreement(false);
    };
    const lifeCatchAgreementButtonLogic = () => {
        var _a;
        alert('hi');
        showLifeCatchInfoDetail();
        allLifeCatchAgreementClose();
        setShowLifeCatchAgreement(true);
        (_a = dialogRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
    };
    const personalInformationAgreementButtonLogic = () => {
        var _a;
        showLifeCatchInfoDetail();
        allLifeCatchAgreementClose();
        setShowPersonalInformationAgreement(true);
        (_a = dialogRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
    };
    const informationAboutEntrepreneurAgreementButtonLogic = () => {
        var _a;
        showLifeCatchInfoDetail();
        allLifeCatchAgreementClose();
        setShowInformationAboutEntrepreneurAgreement(true);
        (_a = dialogRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
    };
    const electronicFinanceButtonLogic = () => {
        var _a;
        showLifeCatchInfoDetail();
        allLifeCatchAgreementClose();
        setShowElectronicFinanceAgreement(true);
        (_a = dialogRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
    };
    const getModalContent = () => {
        if (showLifeCatchAgreement) {
            return React.createElement(PdfViewer, { path: PolicyPath.SERVICE });
        }
        if (showPersonalInformationAgreement) {
            return React.createElement(PdfViewer, { path: PolicyPath.PRIVACY });
        }
        if (showInformationAboutEntrepreneurAgreement) {
            return React.createElement(EntrepreneurPageModal, null);
        }
        if (showElectronicFinanceAgreement) {
            return React.createElement(EntrepreneurPageModal, null);
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(LifeCatchFooterArea, null,
            React.createElement(LifeCatchFooterContainer, null,
                React.createElement(InfoTapContainer, null,
                    React.createElement("div", { style: {
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                        } },
                        React.createElement(InfoTapItem, { onClick: lifeCatchAgreementButtonLogic }, "\uC774\uC6A9\uC57D\uAD00"),
                        React.createElement(Division, null),
                        React.createElement(InfoTapItem, { onClick: personalInformationAgreementButtonLogic }, "\uAC1C\uC778\uC815\uBCF4\uCC98\uB9AC\uBC29\uCE68"),
                        React.createElement(Division, null),
                        React.createElement(InfoTapItem, { onClick: informationAboutEntrepreneurAgreementButtonLogic }, "\uC0AC\uC5C5\uC790\uC815\uBCF4\uD655\uC778"),
                        React.createElement(Division, null),
                        React.createElement(InfoTapItem, { onClick: electronicFinanceButtonLogic }, "\uC804\uC790\uAE08\uC735\uAC70\uB798\uC774\uC6A9\uC57D\uAD00"))),
                React.createElement(GreenSpacer, { spacer: isMobile ? 52 : 24 }),
                React.createElement(GreenSpacer, { spacer: 24 }),
                React.createElement(LifeCatchInfoDescTitleContainer, null,
                    React.createElement(GreenRibbonName, null, "(\uC8FC)\uADF8\uB9B0\uB9AC\uBCF8(GREEN-RIBBON Corp.)")),
                React.createElement(GreenSpacer, { spacer: 11 }),
                React.createElement(InfoDescContainer, null,
                    React.createElement(InfoDescCeoAndCorporateRegistrationNumber, null,
                        React.createElement("div", null, "\uB300\uD45C : \uAE40\uADDC\uB9AC"),
                        React.createElement(Division, null),
                        React.createElement("div", null, "\uC0AC\uC5C5\uC790\uB4F1\uB85D\uBC88\uD638 : 377-86-01897")),
                    React.createElement("div", null, "\uC5F0\uAD6C\uC18C : \uC11C\uC6B8 \uC911\uAD6C \uB0A8\uB300\uBB38\uB85C9\uAE38 24, \uD328\uC2A4\uD2B8\uD30C\uC774\uBE0C\uD0C0\uC6CC 902\uD638 \uADF8\uB9B0\uB9AC\uBCF8\uC5F0\uAD6C\uC18C"),
                    React.createElement("div", null, "\uC8FC\uC18C : \uB300\uC804 \uC720\uC131\uAD6C \uB300\uD559\uB85C 157, \uB514-\uBE0C\uB9BF\uC9C0 \uB5141\uB3D9 \uACF5\uC720\uC624\uD53C\uC2A4-4"),
                    React.createElement("div", null, "\uBA54\uC77C\uC8FC\uC18C : greenribbon@green-ribbon.co.kr"),
                    React.createElement(GreenSpacer, { spacer: 47 }),
                    React.createElement("div", { style: {
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            justifyContent: 'space-between',
                        } },
                        React.createElement("div", { style: {
                                margin: 'auto 0',
                            } }, "Copyright\u24D2 GreenRibbon. All rights reserved."),
                        isMobile ? null : (React.createElement("div", { style: {
                                display: 'flex',
                                gap: '24px',
                            } },
                            React.createElement("a", { href: "https://www.facebook.com/profile.php?id=100067085318897", rel: "noreferrer", target: "_blank" },
                                React.createElement("img", { alt: "fb", src: "/img/footer/ic_fb_gray.svg" })),
                            React.createElement("a", { href: "https://pf.kakao.com/_jxahiK", rel: "noreferrer", target: "_blank" },
                                React.createElement("img", { alt: "kakao", src: "/img/footer/ic_kakaotalk_gray.svg" })),
                            React.createElement("a", { href: "https://www.youtube.com/@lifecatch3673", rel: "noreferrer", target: "_blank" },
                                React.createElement("img", { alt: "youtube", src: "/img/footer/ic_youtube_gray.svg" })),
                            React.createElement("a", { href: "https://www.instagram.com/LifeCatch_Official/", rel: "noreferrer", target: "_blank" },
                                React.createElement("img", { alt: "insta", src: "/img/footer/ic_instagram_gray.svg" }))))),
                    isMobile ? (React.createElement("div", { style: {
                            display: 'flex',
                            gap: '24px',
                            paddingTop: '32px',
                        } },
                        React.createElement("a", { href: "https://www.facebook.com/profile.php?id=100067085318897", rel: "noreferrer", target: "_blank" },
                            React.createElement("img", { alt: "fb", src: "/img/footer/ic_fb_gray.svg" })),
                        React.createElement("a", { href: "https://pf.kakao.com/_jxahiK", rel: "noreferrer", target: "_blank" },
                            React.createElement("img", { alt: "kakao", src: "/img/footer/ic_kakaotalk_gray.svg" })),
                        React.createElement("a", { href: "https://www.youtube.com/@lifecatch3673", rel: "noreferrer", target: "_blank" },
                            React.createElement("img", { alt: "youtube", src: "/img/footer/ic_youtube_gray.svg" })),
                        React.createElement("a", { href: "https://www.instagram.com/LifeCatch_Official/", rel: "noreferrer", target: "_blank" },
                            React.createElement("img", { alt: "insta", src: "/img/footer/ic_instagram_gray.svg" })))) : null)),
            React.createElement(FooterDialog, { ref: dialogRef },
                React.createElement(FooterDialogCloseButton, { onClick: () => {
                        var _a;
                        (_a = dialogRef.current) === null || _a === void 0 ? void 0 : _a.close();
                    }, type: "button" }, "X"),
                getModalContent()))));
};
export default LifeCatchFooter;
